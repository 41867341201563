import classes from './header.module.css';
import GICoding from '../../assets/GICoding.png';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <div className={classes.header}>
      <div className={classes.container}>
        <div className={classes.logo}>
          <img src={GICoding} alt='new logo'></img>
        </div>
        <h1>G. I. Coding</h1>
        <div className={classes.navLinks}>
          <ul>
            <li>
              <Link to='/'>HOME</Link>
            </li>
            <li>
              <Link to='/portfolio'>PORTFOLIO</Link>
            </li>
            <li>
              <Link to='/contact'>CONTACT</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Header;
