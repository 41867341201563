import { Route, BrowserRouter, Switch } from 'react-router-dom';
import ContactPage from './pages/ContactPage';
import HomePage from './pages/HomePage';
import PortfolioPage from './pages/PortfolioPage';

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route exact path='/portfolio'>
            <PortfolioPage />
          </Route>
          <Route exact path='/contact'>
            <ContactPage />
          </Route>
          <Route path='/'>
            <HomePage />
          </Route>
        </Switch>
      </BrowserRouter>
    </>
  );
};

export default App;
