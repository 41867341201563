import classes from './footer.module.css';
import { Link } from 'react-router-dom';
const Footer = () => {
  return (
    <div className={classes.footer}>
      <h1>🇺🇸 VETERAN OWNED 🇺🇸</h1>
      <div className={classes.footerContent}>
        <div className={classes.footerNav}>
          <ul>
            <li>
              <Link to='/'>HOME</Link>
            </li>
            <li>
              <Link to='/portfolio'>PORTFOLIO</Link>
            </li>
            <li>
              <Link to='/contact'>CONTACT</Link>
            </li>
          </ul>
        </div>
        <div className={classes.copyright}>
          <h3>© 2022</h3>
        </div>
      </div>
    </div>
  );
};

export default Footer;
