import Footer from '../components/footer/footer';
import Header from '../components/header/header';
import classes from './PortfolioPage.module.css';
import PHNLogo from '../assets/PHNLogo.png';
import HGHLogo from '../assets/HGHLogo.png';
import IPLogo from '../assets/IPLogo.png';

const PortfolioPage = () => {
  return (
    <div className={classes.portfolioPage}>
      <Header />
      <div className={classes.container}>
        <div className={classes.content}>
          <h1>Portfolio</h1>
          <div className={classes.cardBox}>
            <div className={classes.portfolioCard}>
              <div className={classes.portfolioImg}>
                <a
                  href='https://partyhuntnash.com/'
                  target='_blank'
                  rel='noreferrer'
                >
                  <img src={PHNLogo} alt='logo1'></img>
                </a>
              </div>

              <a
                href='https://partyhuntnash.com/'
                target='_blank'
                rel='noreferrer'
              >
                <h3>Party Hunt Nash</h3>
              </a>

              <p>
                Party Hunt Nash is a web app providing the fun you need going
                out with friends to the best bar in any city. If you need some
                help getting to know strangers and making memories, this web app
                is for you.
              </p>
            </div>

            <div className={classes.portfolioCard}>
              <div className={classes.portfolioImg}>
                <a
                  href='https://home-gym-hero.com/'
                  target='_blank'
                  rel='noreferrer'
                >
                  <img
                    src={HGHLogo}
                    className={classes.logoImg}
                    alt='logo2'
                  ></img>
                </a>
              </div>
              <a
                href='https://home-gym-hero.com/'
                target='_blank'
                rel='noreferrer'
              >
                <h3>Home Gym Hero</h3>
              </a>
              <p>
                Home Gym Hero is a brand preparing for launch. The brand is
                going to serve home fitness enthusiasts from the competitive
                athlete to the beginner ready to improve their health.
              </p>
            </div>

            <div className={classes.portfolioCard}>
              <div className={classes.portfolioImg}>
                <a
                  href='https://informed-prep.web.app/'
                  target='_blank'
                  rel='noreferrer'
                >
                  <img src={IPLogo} alt='logo3'></img>
                </a>
              </div>
              <a
                href='https://informed-prep.web.app/'
                target='_blank'
                rel='noreferrer'
              >
                <h3>Informed Prep</h3>
              </a>

              <p>
                Informed Prep is a brand getting ready to launch. The brand
                revolves around providing podcasts teaching consumers how to be
                prepared for any situation. Then providing the products that
                will help them be prepared.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PortfolioPage;
