import Footer from '../components/footer/footer';
import Header from '../components/header/header';
import classes from './ContactPage.module.css';
import { FiMail } from 'react-icons/fi';
const ContactPage = () => {
  return (
    <div className={classes.contactPage}>
      <Header />
      <div className={classes.container}>
        <div className={classes.content}>
          <h1>Contact Me</h1>
          <div className={classes.contact}>
            <a
              href='mailto:cody@g-i-coding.com?subject= Web Development Inquiry'
              target='_blank'
              rel='noreferrer'
            >
              <div className={classes.email}>
                <FiMail /> <span>cody@g-i-coding.com</span>
              </div>
            </a>
            <p>
              {' '}
              If you'd like to talk about having your website made, please click
              the email above.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContactPage;
