import Footer from '../components/footer/footer';
import Header from '../components/header/header';
import classes from './HomePage.module.css';
import SoloPhoto from '../assets/solo1.jpg';

const HomePage = () => {
  const dayOfWeekName = new Date().toLocaleString('default', {
    weekday: 'long',
  });
  return (
    <div className={classes.homePage}>
      <Header />
      <div className={classes.container}>
        <div className={classes.content}>
          <div className={classes.quote}>
            <h2>
              "A jack of all trades is a master of none, but oftentimes better
              than a master of one." <span> - William Shakespeare</span>
            </h2>
          </div>
          <div className={classes.mainBox}>
            <div className={classes.imgBox}>
              <img src={SoloPhoto} alt='good looking guy'></img>
            </div>
            <div className={classes.mainInfo}>
              <p>
                Hello and happy {dayOfWeekName}. I am Cody Elzey and welcome to
                my website. If you are in need of a website for your business,
                you are in the right place. I am here to build you a website
                that looks good on your desktop and your phone. Feel free to
                email me and I will contact you to discuss turning your vision
                into a beautiful website.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default HomePage;
